// BrandList
import { FC } from 'react';
import { getLocalImageUrl } from 'utils/image';
import styles from './BrandList.module.sass';

const BrandList: FC = () => {
  return (
    <div className={styles.brands}>
      <p>Loved by the best brands</p>
      <div className={styles.brandList}>
        <img
          src={getLocalImageUrl('/images/soona/showcase/each_and_every.png')}
          alt="Each & Every"
        />
        <img
          src={getLocalImageUrl('/images/soona/showcase/subset.png')}
          alt="Subset"
        />
        <img
          src={getLocalImageUrl('/images/soona/showcase/crocs.png')}
          alt="Crocs"
        />
        <img
          src={getLocalImageUrl('/images/soona/showcase/humm.png')}
          alt="Humm Kombucha"
        />
        <img
          src={getLocalImageUrl('/images/soona/showcase/tb12.png')}
          alt="TB12"
        />
      </div>
    </div>
  );
};

export default BrandList;
