import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useRouter } from 'next/router';
import { AUTH_STATUS } from 'types/auth';
import PageLoader from 'components/common/Loader/PageLoader';

interface WithAuthenticationRequiredOptions {
  LoadingComponent: React.FC;
  returnTo: string;
  postAuthRedirect: string;
}

const DefaultLoadingComponent: React.FC = () => <PageLoader />;

const AuthPageProtection: React.FC<
  PropsWithChildren<{
    options?: Partial<WithAuthenticationRequiredOptions>;
  }>
> = ({ children, options = {} }) => {
  const router = useRouter();
  const [routeIsAuthenticated, setRouteAuthenticated] = useState(false);

  const {
    authState: { status, ...authState },
  } = useAuth();

  const {
    returnTo = '/',
    LoadingComponent = DefaultLoadingComponent,
    postAuthRedirect = router.query.redirect,
  } = options;

  if (typeof postAuthRedirect === 'string') setAuthRedirect(postAuthRedirect);

  useEffect(() => {
    if (status === AUTH_STATUS.LOADING || routeIsAuthenticated) return;

    if (status !== AUTH_STATUS.AUTHENTICATED) {
      router.push(returnTo);
    } else {
      setRouteAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, authState, returnTo]);

  return routeIsAuthenticated ? <>{children}</> : <LoadingComponent />;
};

export default AuthPageProtection;

export const setAuthRedirect = (authRedirect: string) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem('post-auth-redirect', authRedirect);
  }
};

export const getAuthRedirect = () => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem('post-auth-redirect');
  }
  return null;
};
